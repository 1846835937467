<template>
	<node
		title="发起人"
		:is-root="true"
		:content="content"
		:header-bg-color="headerBgColor"
		@selected="$emit('selected')"
		@insertNode="type => $emit('insertNode', type)"
		placeholder="所有人"
		header-icon="el-icon-user-solid"
	/>
</template>

<script>
import Node from './Node'

export default {
	name: 'RootNode',
	components: { Node },
	props: {
		nodeInfo: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	computed: {
		content() {
			if (this.nodeInfo.props.assignedUser.length > 0) {
				let texts = []
				this.nodeInfo.props.assignedUser.forEach(user =>
					texts.push(user.nickname)
				)
				return String(texts).replaceAll(',', '、')
			} else {
				return '所有人'
			}
		},
		headerBgColor() {
			if (this.$store.state.flowable.mode === 'viewer') {
				return this.nodeInfo.props.headerBgColor
			} else {
				return '#576a95'
			}
		},
	},
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped></style>
