<template>
	<el-popover
		placement="bottom-start"
		title="添加流程节点"
		width="350"
		trigger="click"
	>
		<div class="node-select">
			<div @click="addApprovalNode">
				<i class="el-icon-s-check" style="color: rgb(255, 148, 62)"></i>
				<span>审批人</span>
			</div>
			<div @click="addConditionsNode">
				<i class="el-icon-share" style="color: rgb(21, 188, 131)"></i>
				<span>条件分支</span>
			</div>
			<div @click="addCcNode">
				<i
					class="el-icon-s-promotion"
					style="color: rgb(50, 150, 250)"
				></i>
				<span>抄送人</span>
			</div>
		</div>
		<el-button
			icon="el-icon-plus"
			slot="reference"
			type="primary"
			size="small"
			circle
		></el-button>
	</el-popover>
</template>

<script>
export default {
	name: 'InsertButton',
	components: {},
	data() {
		return {}
	},
	computed: {
		selectedNode() {
			this.$store.state.flowable.selectedNode
		}
	},
	methods: {
		addApprovalNode() {
			this.$emit('insertNode', 'APPROVAL')
		},
		addCcNode() {
			this.$emit('insertNode', 'CC')
		},
		addDelayNode() {
			this.$emit('insertNode', 'DELAY')
		},
		addConditionsNode() {
			this.$emit('insertNode', 'EXCLUSIVES')
		},
		addConcurrentsNode() {
			this.$emit('insertNode', 'PARALLELS')
		},
		addTriggerNode() {
			this.$emit('insertNode', 'TRIGGER')
		}
	}
}
</script>

<style lang="less" scoped>
.node-select {
	div {
		display: inline-block;
		margin: 5px 5px;
		cursor: pointer;
		padding: 10px 15px;
		border: 1px solid #f8f9f9;
		background-color: #f8f9f9;
		border-radius: 10px;
		width: 130px;
		position: relative;
		span {
			position: absolute;
			left: 65px;
			top: 18px;
		}
		&:hover {
			background-color: #fff;
			box-shadow: 0 0 8px 2px #d6d6d6;
		}
		i {
			font-size: 25px;
			padding: 5px;
			border: 1px solid #dedfdf;
			border-radius: 14px;
		}
	}
}
</style>
