<template>
	<node
		:title="nodeInfo.name"
		:show-error="showError"
		:content="content"
		:error-info="errorInfo"
		:header-bg-color="headerBgColor"
		@selected="$emit('selected')"
		@delNode="$emit('delNode')"
		@insertNode="type => $emit('insertNode', type)"
		placeholder="请设置延时时间"
		header-icon="el-icon-time"
	/>
</template>

<script>
import Node from './Node'

export default {
	name: 'DelayNode',
	props: {
		nodeInfo: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	components: { Node },
	data() {
		return {
			showError: false,
			errorInfo: ''
		}
	},
	computed: {
		headerBgColor() {
			if (this.$store.state.flowable.mode === 'viewer') {
				return this.nodeInfo.props.headerBgColor
			} else {
				return '#f25643'
			}
		},
		content() {
			if (this.nodeInfo.props.type === 'FIXED') {
				return `等待 ${this.nodeInfo.props.time} ${this.getName(
					this.nodeInfo.props.unit
				)}`
			} else if (this.nodeInfo.props.type === 'AUTO') {
				return `至当天 ${this.nodeInfo.props.dateTime}`
			} else {
				return null
			}
		}
	},
	methods: {
		//校验数据配置的合法性
		validate(err) {
			this.showError = false
			try {
				if (this.nodeInfo.props.type === 'AUTO') {
					if ((this.nodeInfo.props.dateTime || '') === '') {
						this.showError = true
						this.errorInfo = '请选择时间点'
					}
				} else {
					if (this.nodeInfo.props.time <= 0) {
						this.showError = true
						this.errorInfo = '请设置延时时长'
					}
				}
			} catch (e) {
				this.showError = true
				this.errorInfo = '配置出现问题'
			}
			if (this.showError) {
				err.push(`${this.nodeInfo.name} 未设置延时规则`)
			}
			return !this.showError
		},
		getName(unit) {
			switch (unit) {
				case 'D':
					return '天'
				case 'H':
					return '小时'
				case 'M':
					return '分钟'
				default:
					return '未知'
			}
		}
	}
}
</script>

<style scoped></style>
