<template>
	<node
		:title="nodeInfo.name"
		:show-error="showError"
		:content="content"
		:error-info="errorInfo"
		:header-bg-color="headerBgColor"
		@selected="$emit('selected')"
		@delNode="$emit('delNode')"
		@insertNode="type => $emit('insertNode', type)"
		placeholder="请设置触发器"
		header-icon="el-icon-set-up"
	/>
</template>

<script>
import Node from './Node'

export default {
	name: 'TriggerNode',
	props: {
		nodeInfo: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	components: { Node },
	data() {
		return {
			showError: false,
			errorInfo: ''
		}
	},
	computed: {
		content() {
			this.nodeInfo
		},
		headerBgColor() {
			if (this.$store.state.flowable.mode === 'viewer') {
				return this.nodeInfo.props.headerBgColor
			} else {
				return '#47bc82'
			}
		},
	},
	methods: {
		//校验数据配置的合法性
		validate(err) {
			this.showError = false
			if (this.nodeInfo.props.type === 'WEBHOOK') {
				if (this.$utils.isNotEmpty(this.nodeInfo.props.http.url)) {
					this.showError = false
				} else {
					this.showError = true
					this.errorInfo = '请设置WEBHOOK的URL地址'
				}
			} else if (this.nodeInfo.props.type === 'EMAIL') {
				if (
					!this.$utils.isNotEmpty(
						this.nodeInfo.props.email.subject
					) ||
					this.nodeInfo.props.email.to.length === 0 ||
					!this.$utils.isNotEmpty(this.nodeInfo.props.email.content)
				) {
					this.showError = true
					this.errorInfo = '请设置邮件发送配置'
				} else {
					this.showError = false
				}
			}
			if (this.showError) {
				err.push(`${this.nodeInfo.name} 触发动作未设置完善`)
			}
			return !this.showError
		}
	}
}
</script>

<style scoped></style>
