<template>
	<node
		:title="nodeInfo.name"
		:show-error="showError"
		:content="content"
		:error-info="errorInfo"
		:header-bg-color="headerBgColor"
		@selected="$emit('selected')"
		@delNode="$emit('delNode')"
		@insertNode="type => $emit('insertNode', type)"
		placeholder="请设置抄送人"
		header-icon="el-icon-s-promotion"
	/>
</template>

<script>
import Node from './Node'

export default {
	name: 'CcNode',
	props: {
		nodeInfo: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	components: { Node },
	data() {
		return {
			showError: false,
			errorInfo: ''
		}
	},
	computed: {
		headerBgColor() {
			if (this.$store.state.flowable.mode === 'viewer') {
				return this.nodeInfo.props.headerBgColor
			} else {
				return '#3296fa'
			}
		},
		content() {
			if (this.nodeInfo.props.shouldAdd) {
				return '由发起人指定'
			} else if (this.nodeInfo.props.assignedUser.length > 0) {
				let texts = []
				this.nodeInfo.props.assignedUser.forEach(user =>
					texts.push(user.nickname)
				)
				return String(texts).replaceAll(',', '、')
			} else {
				return null
			}
		}
	},
	methods: {
		//校验数据配置的合法性
		validate(err) {
			this.showError = false
			if (this.nodeInfo.props.selfSelect.permission) {
				this.showError = false
			} else if (this.nodeInfo.props.assignedUser.length === 0) {
				this.showError = true
				this.errorInfo = '请选择需要抄送的人员'
			}
			if (this.showError) {
				err.push(`抄送节点 ${this.nodeInfo.name} 未设置抄送人`)
			}
			return !this.showError
		}
	}
}
</script>

<style scoped></style>
