<template>
	<div :class="{ node: true, 'node-error-state': showError }">
		<div :class="{ 'node-body': true, error: showError }">
			<div
				class="node-body-left"
				@click="$emit('leftMove')"
				v-if="level > 1 && level != size && $store.state.flowable.mode == 'design'">
				<i class="el-icon-arrow-left"></i>
			</div>
			<div
				class="node-body-main"
				@click="$emit('selected')"
				v-if="level == size"
			>
				<div class="node-body-main-header">
					<span class="title">默认流程</span>
					<span class="level-default">优先级{{ level }}</span>
				</div>
				<div class="node-body-main-content">
					<span class="placeholder">
						未满足其他条件时，将进入默认流程
					</span>
				</div>
			</div>
			<div class="node-body-main" @click="$emit('selected')" v-else>
				<div class="node-body-main-header">
					<ellipsis
						class="title"
						hover-tip
						:content="nodeInfo.name ? nodeInfo.name : '条件' + level" />
					<span :class="$store.state.flowable.mode == 'design' ? 'level' : ''">优先级{{ level }}</span>
					<span
						class="option"
						v-if="$store.state.flowable.mode == 'design'"
					>
						<el-tooltip
							effect="dark"
							content="复制条件"
							placement="top"
						>
							<i class="el-icon-copy-document" @click.stop="$emit('copy')"></i>
						</el-tooltip>
						<i class="el-icon-close" @click.stop="$emit('delNode')"></i>
					</span>
				</div>
				<div class="node-body-main-content">
					<span class="placeholder" v-if="(content || '').trim() === ''">
						请设置条件
					</span>
					<ellipsis hoverTip :row="4" :content="content" v-else />
				</div>
			</div>
			<div
				class="node-body-right"
				@click="$emit('rightMove')"
				v-if="level < size - 1 && $store.state.flowable.mode == 'design'"
			>
				<i class="el-icon-arrow-right"></i>
			</div>
			<div class="node-error" v-if="showError">
				<el-tooltip
					effect="dark"
					:content="errorInfo"
					placement="top-start"
				>
					<i class="el-icon-warning-outline"></i>
				</el-tooltip>
			</div>
		</div>
		<div class="node-footer">
			<div class="btn">
				<insert-button
					v-if="$store.state.flowable.mode == 'design'"
					@insertNode="type => $emit('insertNode', type)"
				></insert-button>
			</div>
		</div>
	</div>
</template>

<script>
import InsertButton from '../InsertButton.vue'

export default {
	name: 'ExclusiveNode',
	components: { InsertButton },
	props: {
		nodeInfo: {
			type: Object,
			default: () => {
				return {}
			}
		},
		//索引位置
		level: {
			type: Number,
			default: 1
		},
		//条件数
		size: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			errorInfo: '',
			showError: false
		}
	},
	computed: {
		content() {
			const groups = this.nodeInfo.props.groups
			const _this = this
			let confitions = []
			groups.forEach(group => {
				let conditionstrs = (group.conditions || []).map(item => {
					return item.fieldText + item.compareText + item.valueText
				})
				if (conditionstrs) {
					if (groups.length > 1) {
						confitions.push(`(${conditionstrs.join(' 且 ')})`)
					} else {
						confitions.push(`${conditionstrs.join(' 且 ')}`)
					}
				}
			})
			//构建最终描述
			return confitions.join(' 或 ')
		}
	},
	methods: {
		getDefault(val, df) {
			return val && val !== '' ? val : df
		},
		//校验数据配置的合法性
		validate(err) {
			if (
				!(this.level == this.size && this.size != 0) &&
				!this.nodeInfo.child?.id
			) {
				this.showError = true
				this.errorInfo = '请设置条件分支的子节点'
				err.push(`条件分支需设置至少一个子节点`)
				return !this.showError
			}

			const props = this.nodeInfo.props
			if (props.groups.length <= 0) {
				this.showError = true
				this.errorInfo = '请设置分支条件'
				err.push(`${this.nodeInfo.name} 未设置条件`)
			} else {
				if (!(this.level == this.size && this.size != 0)) {
					for (
						let groupIndex = 0;
						groupIndex < props.groups.length;
						groupIndex++
					) {
						if (props.groups[groupIndex]?.conditions.length === 0) {
							this.showError = true
							this.errorInfo = `请设置条件组${
								groupIndex + 1
							}内的条件`
							err.push(`条件组${groupIndex + 1}内未设置条件`)
							break
						} else {
							let conditions = props.groups[groupIndex].conditions
							for (
								let conditionIndex = 0;
								conditionIndex < conditions.length;
								conditionIndex++
							) {
								let condition = conditions[conditionIndex]
								if (
									!condition.field ||
									!condition.compare ||
									!condition.value
								) {
									this.showError = true
								} else {
									this.showError = false
								}
								if (this.showError) {
									this.errorInfo = `请完善条件组${
										groupIndex + 1
									}内的${condition.fieldText}条件`
									err.push(
										`条件 ${this.nodeInfo.name} 条件组${
											groupIndex + 1
										}内${condition.fieldText}条件未完善`
									)
									return false
								}
							}
						}
					}
				}
			}
			return !this.showError
		}
	}
}
</script>

<style lang="less" scoped>
.node-error-state {
	.node-body {
		box-shadow: 0px 0px 5px 0px #f56c6c !important;
	}
}

.node {
	padding: 30px 55px 0;
	width: 220px;

	.node-body {
		cursor: pointer;
		min-height: 80px;
		max-height: 120px;
		position: relative;
		border-radius: 5px;
		background-color: white;
		box-shadow: 0px 0px 5px 0px #d8d8d8;

		&:hover {
			.node-body-left,
			.node-body-right {
				i {
					display: block !important;
				}
			}

			.node-body-main {
				.level {
					display: none !important;
				}

				.option {
					display: inline-block !important;
				}
			}

			box-shadow: 0px 0px 3px 0px #1890FF;
		}

		.node-body-left,
		.node-body-right {
			display: flex;
			align-items: center;
			position: absolute;
			height: 100%;

			i {
				display: none;
			}

			&:hover {
				background-color: #ececec;
			}
		}

		.node-body-left {
			left: 0;
		}

		.node-body-right {
			right: 0;
			top: 0;
		}

		.node-body-main {
			//position: absolute;
			width: 188px;
			margin-left: 17px;
			display: inline-block;

			.node-body-main-header {
				padding: 10px 0px 5px;
				font-size: xx-small;
				position: relative;

				.title {
					color: #15bca3;
					display: inline-block;
					font-size: 13px;
				}
				.level-default {
					position: absolute;
					right: 15px;
					color: #888888;
				}
				.level {
					position: absolute;
					right: 15px;
					color: #888888;
				}

				.option {
					position: absolute;
					right: 0;
					display: none;
					font-size: medium;

					i {
						color: #888888;
						padding: 0 3px;
					}
				}
			}

			.node-body-main-content {
				padding: 6px;
				color: #656363;
				font-size: 14px;

				i {
					position: absolute;
					top: 55%;
					right: 10px;
					font-size: medium;
				}

				.placeholder {
					color: #8c8c8c;
				}
			}
		}

		.node-error {
			position: absolute;
			right: -40px;
			top: 20px;
			font-size: 25px;
			color: #f56c6c;
		}
	}

	.node-footer {
		position: relative;

		.btn {
			width: 100%;
			display: flex;
			height: 70px;
			padding: 20px 0 32px;
			justify-content: center;
		}

		/deep/ .el-button {
			height: 32px;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			margin: auto;
			width: 2px;
			height: 100%;
			background-color: #cacaca;
		}
	}
}
</style>
