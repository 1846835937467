<template>
	<node
		:title="nodeInfo.name"
		:show-error="showError"
		:content="content"
		:error-info="errorInfo"
		:header-bg-color="headerBgColor"
		@selected="$emit('selected')"
		@delNode="$emit('delNode')"
		@insertNode="type => $emit('insertNode', type)"
		placeholder="请设置审批人"
		header-icon="el-icon-s-check"
	/>
</template>

<script>
import Node from './Node'

export default {
	name: 'ApprovalNode',
	props: {
		nodeInfo: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	components: { Node },
	data() {
		return {
			showError: false,
			errorInfo: ''
		}
	},
	computed: {
		headerBgColor() {
			if (this.$store.state.flowable.mode === 'viewer') {
				return this.nodeInfo.props.headerBgColor
			}
			if (this.$store.state.flowable.mode === 'setting') {
				if (this.nodeInfo.props.assignedType === 'SELF_SELECT') {
					if (
						this.nodeInfo.props.assignedUser ||
						this.nodeInfo.props.assignedUser.length == 0
					) {
						return '#ff0000'
					}
				}
			}
			return '#ff943e'
		},
		content() {
			const props = this.nodeInfo.props
			switch (props.assignedType) {
				case 'ASSIGN_USER':
					if (props.assignedUser.length > 0) {
						let texts = []
						props.assignedUser.forEach(user =>
							texts.push(user.nickname)
						)
						return String(texts).replaceAll(',', '、')
					} else {
						return '请指定审批人'
					}
				case 'SELF_SELECT':
					let str = ''
					if (props.assignedUser.length > 0) {
						let texts = []
						props.assignedUser.forEach(user =>
							texts.push(user.nickname)
						)
						str = '默认：' + String(texts).replaceAll(',', '、') + '<br />'
					}
					return str + (props.selfSelect.multiple ? '发起人自选多人' : '发起人自选一人')
				case 'SELF':
					return '发起人自己'
				default:
					return '未知设置项😥'
			}
		}
	},
	methods: {
		//校验数据配置的合法性
		validate(err) {
			try {
				this.showError =
					!this[`validate_${this.nodeInfo.props.assignedType}`](err)
				return this.showError
			} catch (e) {
				return true
			}
		},
		validate_ASSIGN_USER(err) {
			if (this.nodeInfo.props.assignedUser.length > 0) {
				return true
			} else {
				this.errorInfo = '请指定审批人员'
				err.push(`${this.nodeInfo.name} 未指定审批人员`)
				return false
			}
		},
		validate_SELF_SELECT(err) {
			if (
				this.nodeInfo.props.nobody.handler === 'TO_USER' &&
				this.nodeInfo.props.nobody.assignedUser.length === 0
			) {
				this.errorInfo =
					'审批人为空时， 转交给指定人员：【请指定一个具体的人】'
				err.push(
					'审批人为空时， 转交给指定人员：【请指定一个具体的人】'
				)
				return false
			}
			return true
		},
		validate_SELF(err) {
			return true
		}
	}
}
</script>

<style scoped></style>
